// IMPORTS
@import (reference) "../settings.less";

// PROMO BANNERS
.promo-banners-wrapper {
    .grid();
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 24px;
}

@media (min-width: 1280px) {
    .promo-banners-wrapper {
        margin-top: -16px;
    }
}

.promo-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 14px;
    outline: 1px solid @border-color;
    position: relative;
    text-align: center;

    > figure {
        width: 48px;
        aspect-ratio: 1;

        img {
            .img-contain();
        }
    }

    > b {
        margin-top: 4px;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: 0.05em;
        font-family: @sec-font-family;
        text-transform: uppercase;
        color: @font-color;
    }

    > p {
        margin-top: 2px;
        font-size: 14px;
        color: @font-color;
    }

    &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 22.75%;
        background: @sec-color;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
    }
}

// MAINPAGE PRODUCTS
.promo-new-wrapper {

    .swiper-outer {
        width: 100%;
        position: relative;
    }

    .swiper-wrapper {
        height: fit-content;
    }

    .product {
        width: ~"calc((100% - ((var(--prodSwiperCols) - 1) * var(--prodGap))) / var(--prodSwiperCols))";
        margin-right: var(--prodGap);
    }
}

.see-all {
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 24px;
    padding: 6px 20px;
    font-size: 14px;
    font-weight: 700;
    color: @main-color;
    border: 1px solid @main-color;
    border-radius: 2px;

    @media (min-width: 1280px) {

        &:hover {
            border-color: @sec-color;
        }
    }
}